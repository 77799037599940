import { builder, BuilderComponent } from '@builder.io/react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import * as React from 'react';
import Link from '../components/Link/Link';
import SEO from '../components/SEO/SEO';

builder.init(process.env.BUILDER_API_KEY);

const defaultDescription = `Remote Team App Store`;

const defaultTitle = 'Time Tracking App';

function LandingPageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const timeTrackingAppPages = models.timeTrackingAppPages[0]?.content;

  return (
    <>
      <SEO
        title={(timeTrackingAppPages && timeTrackingAppPages.data.title) || defaultTitle}
        description={
          (timeTrackingAppPages && timeTrackingAppPages.data.description) || defaultDescription
        }
      />

      <BuilderComponent
        renderLink={Link}
        name="time-tracking-app-pages"
        content={timeTrackingAppPages}
      />
    </>
  );
}

export const timeTrackingAppPagesQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      timeTrackingAppPages(target: { urlPath: $path }, limit: 1) {
        content
      }
    }
  }
`;

LandingPageTemplate.propTypes = {
  props: PropTypes.object.isRequired, // eslint-disable-line
  data: PropTypes.instanceOf(LandingPageTemplate.props), // eslint-disable-line
};

export default LandingPageTemplate;
